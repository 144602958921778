import { paths } from '@/constants'
import { GetComingSoonTabQuery } from '@/types/codegen-federation'
import { TranslateFunction } from '@/utils/translate/translate-client'
import { getWebClient } from '../ApolloClient'
import { LinkViewModel } from '../RenderService'
import { GET_COMING_SOON_TAB } from './queries'
import { ComingSoonNode } from './types'

export const getComingSoonTab = async () => {
  const comingSoonProjects = await getProjectsComingSoon()

  return comingSoonProjects
}

const isTicketsTabPage = (item: ComingSoonNode): boolean => {
  return item?.focus?.type === 'theatrical'
}

const getProjectTabSlug = (item: ComingSoonNode): string | undefined => {
  return item?.project?.slug || undefined
}

const getLinkTabPath = (item: ComingSoonNode, projectSlug: string): string => {
  if (item?.watchable?.__typename === 'ContentEpisode' || item?.watchable?.__typename === 'ContentSpecial') {
    return `${paths.watch.index}/${projectSlug}/episode/${item?.watchable?.id}`
  } else if (item?.project?.public) {
    return `${paths.watch.index}/${projectSlug}`
  } else if (item?.project?.projectType === 'movie') {
    return `${paths.movies.index}/${projectSlug}`
  } else if (item?.project?.projectType === 'series') {
    return `${paths.shows.index}/${projectSlug}`
  } else {
    return `${paths.guild.join}/${projectSlug}`
  }
}

export const buildLinkTabUrl = (item: ComingSoonNode): string => {
  const projectSlug = getProjectTabSlug(item)
  if (!projectSlug) {
    return `${paths.guild.join}`
  }

  if (isTicketsTabPage(item)) {
    return `${paths.tickets.index}/${projectSlug}`
  }

  return getLinkTabPath(item, projectSlug)
}

export const buildLinkTabModel = (item: ComingSoonNode, defaultPortrait?: boolean): LinkViewModel | null => {
  const imageUrl = defaultPortrait
    ? item?.watchable && firstUsablePortraitPath(item.watchable)
    : item?.watchable && firstUsableLandscapePath(item.watchable)

  if (!imageUrl) return null

  const linkUrl = buildLinkTabUrl(item)
  const projectSlug = item?.project?.slug
  const title = item?.title || undefined
  const episodeNumber = item?.project?.seasons?.[0]?.episodes?.[0]?.episodeNumber ?? 1
  const seasonNumber = item?.project?.seasons?.[0]?.seasonNumber ?? 1
  const guid = item?.id

  return {
    alt: title ? `Link to show ${title}` : 'Link to content',
    label: title,
    imageUrl: imageUrl,
    linkUrl,
    track: {
      eventName: 'Coming Soon Thumbnail Clicked',
      payload: {
        linkUrl,
        projectSlug,
        episodeNumber,
        guid,
        seasonNumber,
      },
    },
  }
}

export const buildTabSubtitle = (item: ComingSoonNode, t: TranslateFunction): string => {
  if (item?.focus && item.focus.type === 'guild-access') {
    return t('guildAccess', 'Guild Access')
  } else if (item?.focus?.type === 'theatrical') {
    return t('inTheaters', 'In Theaters')
  } else if (item?.header) {
    return t('coming', 'Coming')
  } else return t('toBeAnnounced', 'To Be Announced')
}

const getProjectsComingSoon = async (): Promise<ComingSoonNode[]> => {
  const client = getWebClient()
  const { data } = await client.query<GetComingSoonTabQuery>({
    query: GET_COMING_SOON_TAB,
    variables: { preview: false, after: null },
    errorPolicy: 'all',
  })
  if (!data?.comingSoonTagGroup?.connection?.edges) return []

  return data.comingSoonTagGroup.connection.edges
    .filter((edge) => !!edge?.node?.project?.slug)
    .map((edge) => edge?.node)
}

export const firstUsableLandscapePath = (watchable: NonNullable<ComingSoonNode>['watchable']) => {
  return (
    watchable.landscapeAngelImage?.cloudinaryPath ||
    watchable.landscapeTitleImage?.cloudinaryPath ||
    watchable.landscapeStillImage?.cloudinaryPath ||
    ''
  )
}

export const firstUsablePortraitPath = (watchable: NonNullable<ComingSoonNode>['watchable']) => {
  return (
    watchable.portraitAngelImage?.cloudinaryPath ||
    watchable.portraitTitleImage?.cloudinaryPath ||
    watchable.portraitStillImage?.cloudinaryPath ||
    ''
  )
}

const monthAbbreviations: { [key: string]: string } = {
  January: 'Jan',
  February: 'Feb',
  March: 'Mar',
  April: 'Apr',
  May: 'May',
  June: 'Jun',
  July: 'Jul',
  August: 'Aug',
  September: 'Sep',
  October: 'Oct',
  November: 'Nov',
  December: 'Dec',
}

export const shortenMonthInHeader = (header: string): string => {
  return header.replace(
    /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/g,
    (match) => {
      return monthAbbreviations[match]
    },
  )
}
