import { GetComingSoonTabQuery } from '@/types/codegen-federation'
import { CatalogTitle } from '../ContentCatalog'
import { Season } from '../ProjectsService'

export interface ComingSoonProject {
  project: ProjectCatalogId
  id?: string | undefined
  slug?: string | null
  name?: string | undefined
  primaryFlow?: PrimaryFlow | null | undefined
  highestScore?: number | null
}

interface ProjectCatalogId {
  slug: string
  contentCatalogId: string
  hasHubPage?: boolean
}

interface PrimaryFlow {
  __typename?: 'Flow'
  flowPhases?: Array<{
    phaseSlugEnum: string | null
    releaseWindows?: Array<{
      start: string
      end?: string | null
      region?: {
        name?: string | null
      }
    } | null> | null
  } | null> | null
}

interface ComingSoonProjectMeta {
  discoveryPosterCloudinaryPath?: string
  name?: string
  projectType?: string
  seasons?: Season[]
  public?: boolean
}

export interface ComingSoonTitle {
  project: ProjectCatalogId
  id?: string | undefined
  slug?: string | null
  name?: string | undefined
  highestScore?: number | null
  title?: CatalogTitle
  meta?: ComingSoonProjectMeta
  primaryFlow?: PrimaryFlow | null | undefined
  guildAccessDate?: Date | null
  guildAccessSpecificity?: string | null
  theatricalReleaseDate?: Date | null
  theatricalReleaseSpecificity?: string | null
  theatricalEndDate?: Date | null
  presaleDate?: Date | null
}

export type ComingSoonNode = NonNullable<
  NonNullable<NonNullable<NonNullable<GetComingSoonTabQuery['comingSoonTagGroup']>['connection']>['edges']>[number]
>['node']

export type ComingSoonEpisode = ComingSoonNode & {
  watchable: Extract<NonNullable<ComingSoonNode>['watchable'], { __typename: 'ContentEpisode' }>
}

export type ComingSoonSpecial = ComingSoonNode & {
  watchable: Extract<NonNullable<ComingSoonNode>['watchable'], { __typename: 'ContentSpecial' }>
}

export function isComingSoonEpisode(node: ComingSoonNode): node is ComingSoonEpisode {
  return node?.watchable.__typename === 'ContentEpisode'
}

export function isComingSoonSpecial(node: ComingSoonNode): node is ComingSoonSpecial {
  return node?.watchable.__typename === 'ContentSpecial'
}
